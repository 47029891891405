import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { trackFooterEvent } from '@lib/gtag';
import { renderImage } from '@lib/image';
import { isLife360Enabled } from '@lib/storefront';

import { DownloadButtons } from '../Footer/Footer';

import style from './DownloadApp.module.scss';

interface Props {
  iosLink?: string;
  androidLink?: string;
  className?: string;
  useTracking?: boolean;
  buttons?: DownloadButtons[]; // Use the buttons prop
}

const DownloadApp: FC<Props> = ({ iosLink, androidLink, className, useTracking = true, buttons = [] }) => {
  const { t } = useTranslation('common');

  // Default links and images
  const defaultIosLink =
    iosLink || (isLife360Enabled() ? t('common:appStore.defaultIosLife360Link') : t('common:appStore.defaultIosLink'));
  const defaultAndroidLink =
    androidLink ||
    (isLife360Enabled() ? t('common:appStore.defaultAndroidLife360Link') : t('common:appStore.defaultAndroidLink'));
  const defaultIosImage = '/svgs/Download_on_the_App_Store.svg';
  const defaultAndroidImage = '/svgs/Download_on_the_Play_Store.svg';

  const renderButton = (
    url: string,
    imageUrl: string,
    alt: string,
    trackingEvent: string,
    width: string = '130',
    height: string = '43'
  ) => (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      title={`${alt} ${t('common:screenReader.openInNewTab')}`}
      onClick={() => {
        if (useTracking) {
          trackFooterEvent(trackingEvent);
        }
      }}
      key={trackingEvent}
    >
      {renderImage({ url: imageUrl, alt }, { loading: 'lazy', height, width })}
    </a>
  );

  return (
    <div className={cn(style.root, className)}>
      {buttons.length === 0 ? (
        // If no buttons are provided, render the default App Store and Google Play buttons
        <>
          {renderButton(defaultIosLink, defaultIosImage, t('common:downloadAppStore'), 'App Store')}
          {renderButton(defaultAndroidLink, defaultAndroidImage, t('common:downloadPlayStore'), 'Google Play Store')}
        </>
      ) : (
        // If buttons are provided, render the custom buttons
        buttons.map((button) => {
          const { url, image, name } = button;
          const buttonUrl = url || (name?.includes('App Store') ? defaultIosLink : defaultAndroidLink);
          const buttonImageUrl = image?.url || (name?.includes('App Store') ? defaultIosImage : defaultAndroidImage);
          const buttonAlt =
            image?.alt || (name?.includes('App Store') ? t('common:downloadAppStore') : t('common:downloadPlayStore'));
          return renderButton(buttonUrl, buttonImageUrl, buttonAlt, name);
        })
      )}
    </div>
  );
};

export default DownloadApp;
