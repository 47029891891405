import { Document } from '@contentful/rich-text-types';
import cn from 'classnames';
import isString from 'lodash/isString';
import { FC, useEffect } from 'react';

import Text from '@components/ui/Text/Text';

import * as componentsDynamic from './components';
import { renderEmbeddedEntries } from './renderEmbeddedEntries';

import s from './RichText.module.scss';

interface Props {
  className?: string;
  document: Document | string;
}

const initDynamicComponents = () => {
  (function doNothing(_components: any): void {
    // do nothing
    // this function is just to ensure renderer config for dynamic components are updated
  })(componentsDynamic);
};

const RichText: FC<Props> = ({ className, document }) => {
  useEffect(() => {
    initDynamicComponents();
  }, []);

  return isString(document) ? (
    <Text className={cn(s.content, className, 'w-full')} html={document} />
  ) : (
    <div className={cn(s.root, className)}>{renderEmbeddedEntries(document)}</div>
  );
};

export default RichText;
